import React from "react";

interface InputProps {
  id?: string;
  label?: string;
  placeholder: string;
  type?: "text" | "password" | "email" | "number";
  [x: string]: any;
}

const Input = ({ id, label, placeholder, type = "text", ...rest }: InputProps) => {
  return (
    <div className="space-y-1.5">
      {label && (
        <label htmlFor={id} className="block text-sm">
          {label} <span className="text-turquoise">*</span>
        </label>
      )}
      <div>
        <input
          id={id ?? label.toLowerCase().replace(/\s+/g, '-')}
          placeholder={placeholder}
          className="w-full px-3 text-sm border rounded-lg !ring-0 h-9 border-neutral-200 hover:border-neutral-300 focus:border-turquoise focus:shadow focus:shadow-turquoise-lighter focus:outline-none placeholder-neutral-500"
          type={type}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Input;
